import React, { useEffect, useRef, useState } from "react";
import { graphql, Link } from "gatsby";
import { getImage, GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import styled from "styled-components";
import cn from "classnames";

const Title = styled.h1`
  margin: 0px;
  font-size: 4rem;
  line-height: 4rem;
  font-weight: normal;
  border: none;
  text-align: left;
`;

const Form = styled.form`
  margin: 50px auto 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 250px;
  height: 200px;
  font-size: 22px;
  border: 1px solid #000;
  border-radius: 3px;
  padding: 60px;

  * {
    font-size: inherit;
  }

  div {
    display: flex;
    flex-direction: column;

    label {
      font-weight: bold;
    }

    input {
      border: 1px solid rgb(0 0 0 / 90%);
      box-sizing: border-box;
      margin: 3px;
      padding: 3px;
    }
  }

  button {
    box-sizing: border-box;
    background: #000;
    color: #fff;
    border: none;
    border-radius: 3px;
    padding: 5px 8px;
    align-self: center;
    width: 100%;
    cursor: pointer;
    transition: box-shadow 0.2s ease;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;

  h1 {
    text-align: center;
    font-size: 50px;
  }
`;

const PostContainer = styled.div`
  max-width: 750px;
  margin: 0 auto;
  min-height: calc(100vh - 400px);
  padding: 30px 30px 100px 30px;

  img {
    box-sizing: border-box;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 3px;
  }

  p:first-of-type {
    margin-top: 3rem;
    /* font-size: 1.6rem; */
  }

  p:first-of-type .dropcap {
    color: var(--color);
    float: left;
    font-size: 6rem;
    font-size: 5rem;
    line-height: 1rem;
    margin: 0;
    padding: 0.5rem;
    padding: 0rem;
  }

  &.isFirstWordSingleLetter p:first-of-type .dropcap {
    padding: 0 1rem 0 0;
  }

  p {
    line-height: 2.5rem;
    line-height: 36px;
    margin: 0;
    padding: 18px 0;
  }

  p:has(img) {
    display: flex;
    justify-content: center;
    img {
      max-width: 600px;
      height: auto;
      margin: 0 auto;
      border: 1px solid rgba(0, 0, 0, 0.3);
      border-radius: 3px;
    }
  }

  ul {
    li {
      font-size: 18px;
    }
  }

  /* hide visually from eyes, but not aurally from screen readers */
  .invisible {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute;
    top: auto;
    white-space: nowrap;
    width: 1px;
  }

  hr {
    background: rgba(0, 0, 0, 0.9);
    border-radius: 3px;
    border: none;
    height: 2px;
  }
`;

const Attribution = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 1.5rem;
  color: gray;
  margin: 15px 0;
  font-weight: normal;

  a {
    color: gray;
  }
`;

function divideDueToDropCapDuplication(text: string) {
  return text.slice(text.length / 2);
}

function isFirstWordSingle(text: string) {
  const words = text.split(" ");

  const firstWord = divideDueToDropCapDuplication(words[0]);
  if (firstWord.length <= 1) {
    return true;
  }
}

function BlogPost(props: any) {
  const [isGrantedAccess, setIsGrantedAccess] = useState(false);
  const post = props.data.markdownRemark;

  const image = getImage(post.frontmatter.image) as IGatsbyImageData;

  const jsxPost = (
    <PostContainer
      className={cn({
        isFirstWordSingleLetter: isFirstWordSingle(post.excerpt),
      })}
    >
      <Title>{post.frontmatter.title}</Title>
      <Attribution>
        <span>
          By{" "}
          <a target="_blank" href="https://github.com/swcfischer">
            Steven Fischer
          </a>{" "}
          | {post.frontmatter.date ?? "March 10, 2022"}{" "}
        </span>
      </Attribution>
      <GatsbyImage image={image} alt="hero" />
      <div dangerouslySetInnerHTML={{ __html: post.html }} />
    </PostContainer>
  );

  // if (post.frontmatter) {
  //   return isGrantedAccess ? (
  //     jsxPost
  //   ) : (
  //     <PasswordForm setIsGrantedAccess={setIsGrantedAccess} />
  //   );
  // }

  return jsxPost;
}

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      frontmatter {
        title
        date
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

export default BlogPost;

const PasswordForm = (props: any) => {
  const ref = useRef<any>();

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, [ref.current]);

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        const val = ref.current.value;
        if (val.match(/steve/gi) || val.match(/fisch/gi)) {
          props.setIsGrantedAccess(true);
        }
      }}
    >
      <div>
        <label htmlFor="password">Password:</label>
        <input ref={ref} type="password" name="password" id="password" />
      </div>
      <button>Submit</button>
      <Link to="/">Or view blog</Link>
    </Form>
  );
};
